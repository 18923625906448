import { ref } from '@vue/composition-api'

export default function differenceTime() {
  const differenceTimeInHours = ref(0)
  const getDifferenceTimeInHours = (firstDate, secondDate) => {
    const firstDateObject = new Date(firstDate)
    const secondDateObject = new Date(secondDate)
    const differenceTimeInMilliSeconds = ref(null)

    differenceTimeInMilliSeconds.value = Math.abs(secondDateObject - firstDateObject)
    const tempDifference = parseFloat((differenceTimeInMilliSeconds.value / 3600000), 10).toFixed(1)
    differenceTimeInHours.value = Number.isNaN(tempDifference) ? 0 : tempDifference
  }
  return {
    differenceTimeInHours,
    getDifferenceTimeInHours,
  }
}
