<template>
  <b-card ref="element">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(editSession)"
      >
        <h4>
          Edit Session
        </h4>
        <b-form-row class="mt-3">
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Topic"
              rules="required|alpha_spaces|min:3|max:200"
            >
              <b-form-group
                label="Topic"
                label-for="topic"
                class="mb-2"
              >
                <b-form-input
                  id="topic"
                  v-model="selectedSessionToEdit.session_provider"
                  placeholder="Topic"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Language"
              rules="required"
            >
              <b-form-group
                label="Language"
                label-for="notes"
              >
                <b-form-select
                  v-model="selectedSessionToEdit.session_languages"
                  :options="languages"
                  value-field="id"
                  text-field="name"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="From"
              rules="required"
            >
              <b-form-group
                label="From"
              >
                <flat-pickr
                  v-model="selectedSessionToEdit.session_start"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:s', minDate: selectedEventDates.start_time,maxDate:selectedEventDates.end_time}"
                  @input="validationContext.validate(selectedSessionToEdit.session_start)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="To"
              rules="required"
            >
              <b-form-group
                label="To"
                class="mb-2"
              >
                <flat-pickr
                  v-model="selectedSessionToEdit.session_end"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:s', minDate: selectedSessionToEdit.session_start}"
                  @on-close="getDifferenceTimeInHours(selectedSessionToEdit.session_start,selectedSessionToEdit.session_end)"
                  @input="validationContext.validate(selectedSessionToEdit.session_end)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Capacity"
              rules="required|max:10"
            >
              <b-form-group
                label="Capacity"
                label-for="capacity"
                class="mb-2"
              >
                <b-form-input
                  id="capacity"
                  v-model="selectedSessionToEdit.session_max_attendance"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Adults Capacity"
              :rules="`required|max_value:${selectedSessionToEdit.session_max_attendance}`"
            >
              <b-form-group
                label="Adults Capacity"
                label-for="adults-capacity"
                class="mb-2"
              >
                <b-form-input
                  id="adults-capacity"
                  v-model="selectedSessionToEdit.session_max_adult"
                  :state="getValidationState(validationContext)"
                  type="number"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Max Family Members"
              :rules="`required|max_value:${selectedSessionToEdit.session_max_attendance}`"
            >
              <b-form-group
                label="Max Family Members"
                label-for="max-family-members"
                class="mb-2"
              >
                <b-form-input
                  id="max-family-members"
                  v-model="selectedSessionToEdit.session_max_family_members"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Target Gender"
              rules="required"
            >
              <b-form-group
                label="Target Gender"
                label-for="gender"
              >
                <b-form-select
                  id="gender"
                  v-model="selectedSessionToEdit.session_target_gender"
                  :options="genderOptions"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Link"
              rules="url"
            >
              <b-form-group
                label="Link"
                label-for="session-url"
                class="mb-2"
              >
                <b-form-input
                  id="session-url"
                  v-model="selectedSessionToEdit.session_link"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="General Notes"
              rules="required"
            >
              <b-form-group
                label="General Notes"
                label-for="notes"
                class="mb-2"
              >
                <b-form-textarea
                  id="notes"
                  v-model="selectedSessionToEdit.session_description"
                  placeholder="General notes for this session"
                  :state="getValidationState(validationContext)"
                  rows="3"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-group
          label="Status"
          label-cols-md="2"
          label-for="status"
          class="mb-2"
        >
          <b-form-checkbox
            id="status"
            v-model="selectedSessionToEdit.session_status"
            value="active"
            unchecked-value="inactive"
          />
        </b-form-group>

        <b-form-group
          label="Auto Approval"
          label-cols-md="2"
          label-for="approval"
          class="mb-2"
        >
          <b-form-checkbox
            id="approval"
            v-model="selectedSessionToEdit.session_approval"
            :value="1"
            :unchecked-value="0"
          />
        </b-form-group>

        <b-form-group
          label="Vaccination Required"
          label-cols-md="2"
          label-for="is-vaccinated"
          class="mb-2"
        >
          <b-form-checkbox
            id="is-vaccinated"
            v-model="selectedSessionToEdit.required_vaccination"
            :value="1"
            :unchecked-value="0"
          />
        </b-form-group>

        <div class="mb-2">
          Period Time : {{ differenceTimeInHours }} Hour/s
        </div>

        <b-button
          variant="primary"
          class="mr-1"
          type="submit"
        >
          Edit Session
        </b-button>
        <back />
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'

import differenceTime from '@/common/compositions/common/differenceTime'
import scroll from '@/common/compositions/common/scrollToElement'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'EditSession',
  components: {
    flatPickr,
    Back,
  },
  props: {
    selectedSessionToEdit: { type: Object, default: () => null },
    selectedEventDates: { type: Object, default: () => null },
    entityId: { type: Number, default: null },
  },
  data() {
    return {
      newSessionData: null,
      languages: [
        { id: 'Arabic', name: 'Arabic' },
        { id: 'English', name: 'English' },
        { id: 'French', name: 'French' },
        { id: 'Spanish', name: 'Spanish' },
        { id: 'Urdu', name: 'Urdu' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  mounted() {
    this.scrollToElementWhenDOMReady()
  },
  setup() {
    const { element, scrollToElement } = scroll()
    const { differenceTimeInHours, getDifferenceTimeInHours } = differenceTime()
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const {
      getValidationState,
    } = formValidation()
    return {
      successfulOperationAlert,
      showErrors,
      differenceTimeInHours,
      getDifferenceTimeInHours,
      element,
      scrollToElement,
      getValidationState,
    }
  },
  methods: {
    editSession() {
      this.setNewSessionData()
      this.$activities.post(`internalops/sessions_update/${this.selectedSessionToEdit.id}?_method=PUT`, this.newSessionData, {
        headers: {
          entityId: this.entityId,
        },
      }).then(() => {
        this.successfulOperationAlert('Session is edited successfully')
        this.$emit('editSession')
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    setNewSessionData() {
      this.newSessionData = {
        session_max_family_members: this.selectedSessionToEdit.session_max_family_members,
        session_start: this.selectedSessionToEdit.session_start,
        session_end: this.selectedSessionToEdit.session_end,
        session_languages: this.selectedSessionToEdit.session_languages,
        session_max_attendance: this.selectedSessionToEdit.session_max_attendance,
        session_max_adult: this.selectedSessionToEdit.session_max_adult,
        session_status: this.selectedSessionToEdit.session_status,
        session_target_gender: this.selectedSessionToEdit.session_target_gender,
        session_approval: this.selectedSessionToEdit.session_approval,
        session_provider: this.selectedSessionToEdit.session_provider,
        required_vaccination: this.selectedSessionToEdit.required_vaccination,
        session_description: this.selectedSessionToEdit.session_description,
        session_link: this.selectedSessionToEdit.session_link,
      }
    },
    scrollToElementWhenDOMReady() {
      setTimeout(() => {
        this.scrollToElement()
      })
    },
  },
}
</script>
<style lang="">

</style>
