<template>
  <b-card ref="element">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(createSession)"
      >
        <h4>Add New Session</h4>
        <b-form-row class="mt-3">
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Topic"
              rules="required|alpha_spaces|min:3|max:200"
            >
              <b-form-group
                label="Topic"
                label-for="topic"
                class="mb-2"
              >
                <b-form-input
                  id="topic"
                  v-model="sessionData.session_provider"
                  placeholder="Topic"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="Language"
              rules="required"
            >
              <b-form-group
                label="Language"
                label-for="notes"
              >
                <b-form-select
                  v-model="sessionData.session_languages"
                  :options="languages"
                  value-field="id"
                  :state="getValidationState(validationContext)"
                  text-field="name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="From"
              rules="required"
              :detect-input="false"
            >
              <b-form-group
                label="From"
              >
                <flat-pickr
                  v-model="sessionData.session_start"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: selectedEventDates.start_time,maxDate:selectedEventDates.end_time}"
                  @input="validationContext.validate(sessionData.session_start)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="To"
              rules="required"
              :detect-input="false"
            >
              <b-form-group
                label="To"
                class="mb-2"
              >
                <flat-pickr
                  v-model="sessionData.session_end"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    minDate: $moment(sessionData.session_start).add(5,'minutes').format('YYYY-MM-DD HH:mm'),
                    maxDate: isItEditEvent? selectedEventDates.end_time: maxDate,
                  }"
                  @input="validationContext.validate(sessionData.session_end)"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Capacity"
              rules="required"
            >
              <b-form-group
                label="Capacity"
                label-for="capacity"
                class="mb-2"
              >
                <b-form-input
                  id="capacity"
                  v-model="sessionData.session_max_attendance"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Adults Capacity"
              :rules="`required|max_value:${sessionData.session_max_attendance}`"
            >
              <b-form-group
                label="Adults Capacity"
                label-for="adults-capacity"
                class="mb-2"
              >
                <b-form-input
                  id="adults-capacity"
                  v-model="sessionData.session_max_adult"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Max Family Members"
              :rules="`required|max_value:${sessionData.session_max_attendance}`"
            >
              <b-form-group
                label="Max Family Members"
                label-for="max-family-members"
                class="mb-2"
              >
                <b-form-input
                  id="max-family-members"
                  v-model="sessionData.session_max_family_members"
                  type="number"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Target Gender"
              rules="required"
            >
              <b-form-group
                label="Target Gender"
                label-for="gender"
              >
                <b-form-select
                  id="gender"
                  v-model="sessionData.session_target_gender"
                  :options="genderOptions"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Link"
              rules="url"
            >
              <b-form-group
                label="Link"
                label-for="session-url"
                class="mb-2"
              >
                <b-form-input
                  id="session-url"
                  v-model="sessionData.session_link"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-form-row>

        <validation-provider
          #default="validationContext"
          name="General Notes"
          rules="required"
        >
          <b-form-group
            label="General Notes"
            label-for="notes"
            class="mb-2"
          >
            <b-form-textarea
              id="notes"
              v-model="sessionData.session_description"
              placeholder="General notes for this session"
              rows="3"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group
          label="Status"
          label-cols-md="2"
          label-for="status"
          class="mb-2"
        >
          <b-form-checkbox
            id="status"
            v-model="sessionData.session_status"
            value="active"
            unchecked-value="inactive"
          />
        </b-form-group>

        <b-form-group
          label="Auto Approval"
          label-cols-md="2"
          label-for="approval"
          class="mb-2"
        >
          <b-form-checkbox
            id="approval"
            v-model="sessionData.session_approval"
            :value="1"
            :unchecked-value="0"
          />
        </b-form-group>

        <b-form-group
          label="Vaccination Required"
          label-cols-md="2"
          label-for="is-vaccinated"
          class="mb-2"
        >
          <b-form-checkbox
            id="is-vaccinated"
            v-model="sessionData.required_vaccination"
            :value="1"
            :unchecked-value="0"
          />
        </b-form-group>

        <div class="mb-2">
          Period Time : {{ differenceTimeInHours }} Hour/s
        </div>

        <b-button
          variant="primary"
          class="mr-1"
          type="submit"
        >
          Add Session
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import differenceTime from '@/common/compositions/common/differenceTime'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import scroll from '@/common/compositions/common/scrollToElement'

export default {
  name: 'AddSession',
  components: {
    flatPickr,
  },
  props: {
    selectedEventId: { type: Number, default: null },
    selectedEventDates: { type: Object, default: null },
  },
  data() {
    return {
      sessionData: {
        activity_id: null,
        session_start: null,
        session_end: null,
        session_languages: null,
        session_max_attendance: null,
        session_max_adult: null,
        session_status: 'inactive',
        session_target_gender: null,
        session_approval: 1,
        session_provider: null,
        session_max_family_members: null,
        required_vaccination: 0,
        session_description: null,
      },
      languages: [
        { id: 'Arabic', name: 'Arabic' },
        { id: 'English', name: 'English' },
        { id: 'French', name: 'French' },
        { id: 'Spanish', name: 'Spanish' },
        { id: 'Urdu', name: 'Urdu' },
      ],
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  computed: {
    isItEditEvent: {
      get() {
        return !!this.$router.currentRoute.params.id
      },
    },
    maxDate: {
      get() {
        const date = new Date(this.sessionData.session_start)
        return date.setDate(date.getDate() + 1)
      },
    },
    endDate: {
      get() {
        return this.sessionData.session_end
      },
    },
  },
  watch: {
    endDate() {
      this.getDifferenceTimeInHours(this.sessionData.session_start, this.sessionData.session_end)
    },
  },
  mounted() {
    this.scrollToElement()
  },
  setup() {
    const { element, scrollToElement } = scroll()
    const {
      getValidationState,
    } = formValidation()
    const { differenceTimeInHours, getDifferenceTimeInHours } = differenceTime()
    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      differenceTimeInHours,
      getDifferenceTimeInHours,
      successfulOperationAlert,
      showErrors,
      element,
      scrollToElement,
      getValidationState,
    }
  },
  methods: {
    createSession() {
      this.setCreatedEventID()
      this.$activities.post('internalops/prayer-activities/addsessions', this.sessionData).then(() => {
        this.successfulOperationAlert('Session is added successfully')
        this.updateSessionsTable()
      }).catch(error => {
        this.showErrors(error.response.data.errors)
      })
    },
    setCreatedEventID() {
      this.sessionData.activity_id = this.selectedEventId
    },
    updateSessionsTable() {
      this.$emit('addSession')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
